<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row col>
          <b-col md="6">
            <b-form-group
              id="pageLabel"
              label="Page Name"
              label-for="mc-pageType"
            >
              <validation-provider
                #default="{ errors }"
                name="pageType"
                rules="required|max:60"
              >
                <b-form-input
                  id="pageType"
                  v-model="ContentEditForm.page_type"
                  readonly
                  :state="errors.length > 0 ? false : null"
                  placeholder="Page Type"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              id="englishLabel"
              label="English Content"
              label-for="mc-en_content"
            >
              <validation-provider
                #default="{ errors }"
                name="English Content"
                rules="required"
              >
                <template>
                  <quill-editor
                    v-model="ContentEditForm.en_content"
                    :options="snowOption"
                  />
                </template>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              id="hungaryLabel"
              label="Hungarian Content"
              label-for="mc-hu_content"
            >
              <validation-provider
                #default="{ errors }"
                name="Hungarian Content"
                rules="required"
              >
                <template>
                  <quill-editor
                    v-model="ContentEditForm.hu_content"
                    :options="snowOption"
                  />
                </template>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="12" class="demo-inline-spacing">
            <b-button
              :disabled="loading"
              variant="primary"
              type="submit"
              @click="updateStaticContent"
            >
              Submit
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button variant="secondary" :to="{ name: 'static-content' }">
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  alpha
} from "@core/utils/validations/validations";
import staticContentService from "@/services/content-admin/contentAdmin.service";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { resConditionCheck } from "@/helpers/constant";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    quillEditor
  },

  data() {
    return {
      ContentEditForm: {
        page_type: "",
        en_content: "",
        hu_content: ""
      },
      required,
      email,
      confirmed,
      alpha,
      loading: false,
      toolbarOptions: [],
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike", "align", "link"],
            [{ list: "ordered" }, { list: "bullet" }]
          ]
        }
      },

      deleteMsg: "",
      titleMsg: "",
      resConditionCheck
    };
  },

  mounted() {
    this.getStaticContentById(this.$route.params.id);
  },

  methods: {
    async getStaticContentById(id) {
      await staticContentService
        .getStaticContentById(id)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.ContentEditForm.page_type = res.data.data.pageType;
            this.ContentEditForm.en_content = res.data.data.enContent;
            this.ContentEditForm.hu_content = res.data.data.huContent;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        });
    },

    updateStaticContent() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true;
          staticContentService
            .editStaticContent(this.$route.params.id, this.ContentEditForm)
            .then(res => {
              if (resConditionCheck(res.status) && res.data.data) {
                this.loading = false;
                this.$router.push("/content/static-content");
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "CheckIcon",
                    variant: "success"
                  }
                });
              }
            })
            .catch(error => {
              const errorData = errorResponseHandler(error);
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger"
                }
              });
            });
        }
      });
    }
  }
};
</script>

<style>
.ql-editor {
  min-height: 150px;
  max-height: 350px;
}
</style>
