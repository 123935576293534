import axios from 'axios'

function getStaticContentListing(payload) {
  return axios.get('cms/list', { params: payload })
}

function editStaticContent(id, data) {
  return axios.put(`cms/${id}`, data)
}

function getStaticContentById(id) {
  return axios.get(`cms/${id}`)
}

function deletePage(id) {
  return axios.delete(`cms/${id}`)
}

export default {
  getStaticContentListing,
  editStaticContent,
  getStaticContentById,
  deletePage
}
